import { Inject, Injectable } from '@angular/core';

import { catchError, EMPTY, filter, finalize, forkJoin, from, mergeMap, of, take, tap } from 'rxjs';

import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { APP_ENVIRONMENT } from '@grants/shared/tokens';
import { Environment } from '@grants/shared/models';

import { AuthActions } from '../actions';
import { KeycloakProfile, UserModel } from '../models';
import { parseJwt } from './util';
import { removeId } from '@jsonforms/core';

export interface StateModel {
    isReady: boolean;
    isInitializing: boolean;
    isKeycloakReady: boolean;
    isAuthenticated: boolean;
    currentProfile: KeycloakProfile | null;
    currentUser: UserModel | null;
    roles: string[];
    returnUrl: string | null;
    token: string | null;
    errors: any;
}

@State<StateModel>({
    name: 'auth',
    defaults: {
        isReady: false,
        isInitializing: true,
        isKeycloakReady: false,
        isAuthenticated: false,
        currentProfile: null,
        currentUser: null,
        roles: [],
        returnUrl: null,
        token: null,
        errors: null,
    },
})
@Injectable()
export class AuthState {
    static getToken() {
        return createSelector([AuthState], (state: StateModel) => state.token);
    }

    static getCurrentProfile() {
        return createSelector([AuthState], (state: StateModel) => state.currentProfile);
    }

    static getCurrentUser() {
        return createSelector([AuthState], (state: StateModel) => state.currentUser);
    }

    static isReady() {
        return createSelector([AuthState], (state: StateModel) => state.isReady);
    }

    static isKeycloakReady() {
        return createSelector([AuthState], (state: StateModel) => state.isKeycloakReady);
    }

    static isInitializing() {
        return createSelector([AuthState], (state: StateModel) => state.isInitializing);
    }

    static isAuthenticated() {
        return createSelector([AuthState], (state: StateModel) => state.isAuthenticated);
    }

    static getRoles() {
        return createSelector([AuthState], (state: StateModel) => state.roles);
    }

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private keycloakService: KeycloakService,
        private store: Store
    ) {}

    ngxsOnInit({ dispatch, patchState }: StateContext<any>) {
        this.store
            .select(AuthState.isKeycloakReady())
            .pipe(
                filter((isKeycloakReady) => isKeycloakReady),
                take(1),
                mergeMap(() => {
                    return dispatch(new AuthActions.LoadKeycloakProfile());
                }),
                finalize(() => {
                    patchState({ isInitializing: false, isReady: true });
                })
            )
            .subscribe();

        dispatch(new AuthActions.InitializeKeycloak());
    }

    @Action(AuthActions.LoadKeycloakProfile)
    loadKeycloakProfile({ patchState, dispatch }: StateContext<StateModel>) {
        return of(this.keycloakService.isLoggedIn()).pipe(
            mergeMap((isAuthenticated) => {
                patchState({ isAuthenticated });

                if (isAuthenticated) {
                    return forkJoin({
                        profile: from(this.keycloakService.loadUserProfile()),
                        token: from(this.keycloakService.getToken()),
                    }).pipe(
                        mergeMap(({ profile, token }) => {
                            const jwt = parseJwt(token);
                            const roles = jwt.resource_access[this.env.abGrantApi.security_client_id]?.roles || [];
                            patchState({
                                currentProfile: profile,
                                token,
                                roles,
                            });

                            return dispatch(new AuthActions.LoadCurrentUser());
                        })
                    );
                }

                return of('');
            })
        );
    }

    @Action(AuthActions.RefreshToken)
    refreshToken({ dispatch }: StateContext<StateModel>) {
        return from(this.keycloakService.updateToken(-1)).pipe(
            mergeMap(() => dispatch(new AuthActions.LoadKeycloakProfile()))
        );
    }

    @Action(AuthActions.LoadCurrentUser)
    loadUser({ patchState, dispatch }: StateContext<StateModel>) {
        // Go to our database and get the user, or create a new user
    }

    @Action(AuthActions.InitializeKeycloak)
    initializeKeycloak({ patchState }: StateContext<StateModel>) {
        const promise = this.keycloakService.init({
            config: {
                url: this.env.access.url + '/auth',
                realm: this.env.access.realm,
                clientId: this.env.access.client_id,
            },
            initOptions: {
                onLoad: 'check-sso',

                // silentCheckSsoRedirectUri:
                //     window.location.origin + '/assets/silent-check-sso.html',
            },
            bearerExcludedUrls: ['amazonaws.com'],
        });

        return from(promise).pipe(
            tap(() => {
                patchState({ isKeycloakReady: true });
            }),
            catchError((err) => {
                debugger;
                console.log(err);
                return EMPTY;
            })
        );
    }

    @Action(AuthActions.Login)
    login(ctx: StateContext<StateModel>, { idpHint, redirectUrl, force }: AuthActions.Login) {
        if(!redirectUrl){
            redirectUrl = `${window.location.href.split('?')[0]}`;
        }
        return of(this.keycloakService.isLoggedIn()).pipe(
            tap((isLoggedIn) => {
                if (!isLoggedIn || force === true) {
                    this.keycloakService.login({
                        idpHint: idpHint || undefined,
                        redirectUri: redirectUrl,
                    });
                }
            })
        );
    }

    @Action(AuthActions.Logout)
    logout() {
        this.keycloakService.logout();
    }
}
