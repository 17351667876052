import { HttpRequest } from '@angular/common/http';

const ns = '[Auth]';

export class Login {
    static readonly type = `${ns} Login`;
    constructor(
        public redirectUrl?: string | null,
        public idpHint: string | null = 'madi_saml',
        public force = false
    ) {}
}

export class Logout {
    static readonly type = `${ns} Logout`;
}

export class Initialize {
    static readonly type = `${ns} Initialize`;
}

export class LoadCurrentUser {
    static readonly type = `${ns} LoadCurrentUser`;
}

export class InitializeKeycloak {
    static readonly type = `${ns} InitializeKeycloak`;
}

export class LoadKeycloakProfile {
    static readonly type = `${ns} LoadKeycloakProfile`;
}

export class RegisterUser {
    static readonly type = `${ns} RegisterUser`;
}

export class RefreshToken {
    static readonly type = `${ns} Refresh Token`;
}

export class Unauthorized {
    static readonly type = `${ns} Unauthorized`;
    constructor(public request: HttpRequest<any>) {}
}

export class UnauthorizedResolved {
    static readonly type = `${ns} Unauthorized Resolved`;
    constructor(public action: 'retry' | 'throw') {}
}
