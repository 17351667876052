import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';

import { AuthState } from '../state';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.store.selectSnapshot(AuthState.getToken());

        // const originReqContentType = req.headers.get("Content-Type")??'application/json';

        if (token != null) {
            req = req.clone({
                setHeaders: {
                    // 'Content-Type': 
                    //     req.method === 'PATCH' ? 'application/json-patch+json' : originReqContentType,
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
        }

        return next.handle(req);
    }
}
