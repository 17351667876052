// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import { Environment } from '@grants/shared/models';

export const environment: Environment = {
  production: false,
  debug: false,
  label: '###BRANCH_LABEL###',
  version: '###APP_VERSION###',
  commit_sha: '###COMMIT_SHA###',
  signUpUrl: 'https://account.alberta.ca/signup',

  access: {
    url: 'https://access.alberta.ca',
    realm: '691bce33-23b8-4caa-8b5c-a709f12abdd4',
    client_id: 'goa:grant-intake-ui',
    adspFormSvcUrl: 'https://form-service.adsp.alberta.ca'
  },
  abGrantApi: {
    host: 'https://agriculturalintakemanagement.api.alberta.ca/api',
    security_client_id: 'goa:abgrant-user-roles-client',
    endpoints: {
      tenantNameByRealm: '/api/tenant/v1/realm',
    },
  },
};
