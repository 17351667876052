import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, EMPTY, finalize, mergeMap, Observable, take } from 'rxjs';

import { Actions, ofActionDispatched, Store } from '@ngxs/store';

import { AuthActions } from '../actions';
import { AUTH_IGNORE_ROUTES } from '../tokens';

@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(
        @Inject(AUTH_IGNORE_ROUTES) private ignoredRoutes: string[],
        private store: Store,
        private actions: Actions
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.ignoredRoutes.some((route) => req.url.includes(route))) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    // try and see if we can refresh the token
                    this.store.dispatch(new AuthActions.Unauthorized(req));

                    // wait for the action resolved before continuing
                    return this.actions.pipe(
                        ofActionDispatched(AuthActions.UnauthorizedResolved),
                        take(1),
                        mergeMap(({ action }) => {
                            if (action === 'retry') {
                                // retry the request because maybe things will work?
                                return next.handle(req);
                            }

                            throw error; // rethrow the error and let the original caller handle it
                        })
                    );
                }
                throw error; // not a 401 or 403, so rethrow the error
            }),
            finalize(() => {
                console.log(`Request for ${req.url} has completed`);
            })
        );
    }
}
