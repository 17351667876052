import { VERSION as ngVersion } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { VERSION as ngMaterialVersion } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import '@abgov/web-components';

import { DataModule } from '@grants/data';
import { AuthInterceptor, UnauthorizedInterceptor } from '@grants/data/interceptors';
import { SharedModule } from '@grants/shared';
import { APP_ENVIRONMENT } from '@grants/shared/tokens';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AUTH_IGNORE_ROUTES } from '@grants/data/tokens';

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, DataModule, SharedModule, AppRoutingModule],
    providers: [
        { provide: APP_ENVIRONMENT, useValue: environment },
        { provide: AUTH_IGNORE_ROUTES, useValue: [] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(public appRef: ApplicationRef) {
        console.log('@angular/core', ngVersion.full);
        console.log('@angular/material', ngMaterialVersion.full);
    }
}
