import { Pipe, PipeTransform } from '@angular/core'; 
import { isNullOrWhiteSpace } from '../utilities';
  
@Pipe({ 
    name: 'prettyJSON'
}) 

export class PrettyJSONPipe implements PipeTransform { 
  
    transform(json: any): string {
        return formatPrettyJSONHTML(json);
    } 
}

export function formatPrettyJSONHTML(json: any): string {
    if (typeof json === 'string' &&
        !isNullOrWhiteSpace(json)) {
        try {
            json = JSON.parse(json);
        } catch (error) {
            return '';
        }
    }

    return JSON.stringify(json, undefined, 4) 
        .replace(/ /g, ' ') 
        .replace(/\n/g, '<br/>'); 
}
