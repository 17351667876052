import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AuthState } from './state';

@NgModule({
    imports: [
        CommonModule,
        KeycloakAngularModule,
        HttpClientModule,

        NgxsModule.forRoot([AuthState]),
        NgxsRouterPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
    ],
})
export class DataModule {}
