import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AngularComponentsModule } from '@abgov/angular-components';

import { AccessDeniedComponent, PageNotFoundComponent } from './components';
import { AnyPipe, FileSizePipe, InvalidControlPipe, IsEmptyPipe, PrettyJSONPipe } from './pipes';
import { FormFieldDirective } from './directives/form-field.directive';

const DECLARATIONS = [
    PageNotFoundComponent,
    AccessDeniedComponent,

    FormFieldDirective,

    InvalidControlPipe,
    FileSizePipe,
    AnyPipe,
    IsEmptyPipe,
    PrettyJSONPipe,
];

@NgModule({
    imports: [CommonModule, AngularComponentsModule],

    declarations: [...DECLARATIONS],

    exports: [...DECLARATIONS],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
