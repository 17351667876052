/* eslint-disable @typescript-eslint/no-inferrable-types */
import { distinct } from './linq';
import { isNullOrUndefined } from './object';

export function isNullOrEmpty(input: string | null | undefined): input is undefined | null | '' {
    if (input == '\n' || input == '\r' || input == '\t') {
        return false;
    }

    if (isNullOrUndefined(input)) {
        return true;
    }

    return (input as string)?.length === 0;
}

export function isNullOrWhiteSpace(input: string | null | undefined): boolean {
    if (isNullOrUndefined(input)) {
        return true;
    }

    return (input as string).replace(/\s/g, '').length < 1;
}

export function includes(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true,
    position: number | undefined = undefined
): boolean {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        if (ignoreCase) {
            return (input as string).toLowerCase().includes((searchString as string).toLowerCase(), position);
        } else {
            return (input as string).includes(searchString as string, position);
        }
    }

    return false;
}

export function startsWith(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true,
    position: number | undefined = undefined
): boolean {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        if (ignoreCase) {
            return (input as string).toLowerCase().startsWith((searchString as string).toLowerCase(), position);
        } else {
            return (input as string).startsWith(searchString as string, position);
        }
    }

    return false;
}

export function endsWith(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true,
    position: number | undefined = undefined
): boolean {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        if (ignoreCase) {
            return (input as string).toLowerCase().endsWith((searchString as string).toLowerCase(), position);
        } else {
            return (input as string).endsWith(searchString as string, position);
        }
    }

    return false;
}

export function trim(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrim(input, searchString, ignoreCase, true, true);
}

export function trimStart(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrim(input, searchString, ignoreCase, true, false);
}

export function trimEnd(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrim(input, searchString, ignoreCase, false, true);
}

export function internalTrim(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean,
    trimStart: boolean,
    trimEnd: boolean
): string {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        let start = 0;
        let end = (input as string).length;
        const length = (searchString as string).length;

        while (
            // eslint-disable-next-line no-unmodified-loop-condition
            trimStart &&
            start < end &&
            hasSubstringAt(input, searchString, ignoreCase, start)
        ) {
            start += (searchString as string).length;
        }

        while (
            // eslint-disable-next-line no-unmodified-loop-condition
            trimEnd &&
            end > start &&
            hasSubstringAt(input, searchString, ignoreCase, end - length)
        ) {
            end -= (searchString as string).length;
        }

        return start > 0 || end < (input as string).length
            ? (input as string).substring(start, end)
            : (input as string);
    } else {
        input = '';
    }

    return input as string;
}

export function trimAll(
    input: string | null | undefined,
    searchStrings: string[] | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrimAll(input, searchStrings, ignoreCase, true, true);
}

export function trimAllStart(
    input: string | null | undefined,
    searchStrings: string[] | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrimAll(input, searchStrings, ignoreCase, true, false);
}

export function trimAllEnd(
    input: string | null | undefined,
    searchStrings: string[] | null | undefined,
    ignoreCase: boolean = true
): string {
    return internalTrimAll(input, searchStrings, ignoreCase, false, true);
}

export function internalTrimAll(
    input: string | null | undefined,
    searchStrings: string[] | null | undefined,
    ignoreCase: boolean,
    trimStart: boolean,
    trimEnd: boolean
): string {
    if (isNullOrUndefined(input)) {
        return '';
    }

    if (isNullOrUndefined(searchStrings)) {
        searchStrings = [];
    }

    searchStrings = distinct(searchStrings);

    let originalInput: string;

    do {
        originalInput = input as string;

        for (const searchString of searchStrings) {
            input = internalTrim(input, searchString, ignoreCase, trimStart, trimEnd);
        }
    } while (input !== originalInput);

    return input;
}

export function hasSubstringAt(
    input: string | null | undefined,
    searchString: string | null | undefined,
    ignoreCase: boolean = true,
    position: number = 0
): boolean {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        let index = 0;
        const length = (searchString as string).length;

        for (const max = (input as string).length; index < length; ++index) {
            if (ignoreCase) {
                if (
                    position + index >= max ||
                    (input as string)[position + index].toLowerCase() != (searchString as string)[index].toLowerCase()
                ) {
                    break;
                }
            } else {
                if (position + index >= max || (input as string)[position + index] != (searchString as string)[index]) {
                    break;
                }
            }
        }

        return index === length;
    }

    return false;
}

export function replace(
    input: string | null | undefined,
    searchString: string | null | undefined,
    replacementValue: string | null | undefined,
    ignoreCase: boolean = true
): string {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        let regEx: RegExp;

        if (isNullOrEmpty(replacementValue)) {
            replacementValue = '';
        }

        // for(const characterToEscape of ['(', ')', '|']) {
        //     let startIndex = 0;
        //     let characterToEscapeIndex = (searchString as string).indexOf(characterToEscape, startIndex);
        //     let done = characterToEscapeIndex < 0;

        //     if (!done) {
        //         do
        //         {
        //             searchString = (searchString as string).replace(characterToEscape, `\\${characterToEscape}`);

        //             startIndex = characterToEscapeIndex + 2;
        //             characterToEscapeIndex = (startIndex < searchString.length)
        //                 ? searchString.indexOf(characterToEscape, startIndex)
        //                 : -1;
        //             done = characterToEscapeIndex < 0;
        //         } while (!done);
        //     }
        // }

        searchString = (searchString as string).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

        if (ignoreCase) {
            regEx = new RegExp(searchString, 'ig');
        } else {
            regEx = new RegExp(searchString, 'g');
        }

        input = (input as string).replace(regEx, replacementValue as string);

        let startIndex = 0;
        let replacementIndex = input.toLowerCase().indexOf(searchString.toLowerCase(), startIndex);
        let done = replacementIndex < 0;

        if (!done) {
            do {
                input = input.replace(searchString, replacementValue as string);

                startIndex = replacementIndex + 1;
                replacementIndex =
                    startIndex < searchString.length ? searchString.indexOf(searchString, startIndex) : -1;
                done = replacementIndex < 0;
            } while (!done);
        }

        return input;
    }

    return input as string;
}

export function matches(input: string | null | undefined, searchString: string | null | undefined): boolean {
    if (!isNullOrWhiteSpace(input) && !isNullOrEmpty(searchString)) {
        if (searchString === '*') {
            return true;
        }

        if (startsWith(searchString, '*')) {
            searchString = '^' + (searchString as string).substring(1);
        }

        if (endsWith(searchString, '*')) {
            searchString = '$' + (searchString as string).substring(0, (searchString as string).length - 1);
        }

        const regEx = new RegExp(searchString as string);

        const result = (input as string).match(regEx);

        return !isNullOrUndefined(result) && (result as RegExpMatchArray).length > 0;
    }

    return false;
}

export type AndOrType = 'and' | 'or' | 'and/or';

export function getListOfItems(names: string[], defaultValue: string = '', andOrType: AndOrType = 'and/or'): string {
    if (isNullOrUndefined(names) || names.length === 0) {
        return defaultValue;
    }

    names = names.filter((x) => !isNullOrWhiteSpace(x)).map((x) => x.trim());

    if (isNullOrUndefined(names) || names.length === 0) {
        return defaultValue;
    }

    if (names.length === 1) {
        return names[0];
    }
    else if (names.length == 2) {
        return `${names[0]} ${andOrType} ${names[1]}`;
    }

    let value = '';

    for (let index = 0; index < names.length; index++) {
        if (index === 0) {
            value += names[index];
        } else if (index < names.length - 1) {
            value += `, ${names[index]}`;
        } else {
            value += `, ${andOrType} ${names[index]}`;
        }
    }

    return value;
}

export function toPlural(text: string | null | undefined): string | null | undefined {
    if (isNullOrWhiteSpace(text)) {
        return text;
    }

    if (text?.toLowerCase().endsWith('s') || text?.toLowerCase().endsWith('z')) {
        return `${text}es`;
    }

    return `${text}s`;
}

export function toPossessive(text: string | null | undefined): string | null | undefined {
    if (isNullOrWhiteSpace(text)) {
        return text;
    }

    if (text?.toLowerCase().endsWith('s')) {
        return `${text}'`;
    }

    return `${text}'s`;
}
